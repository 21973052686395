import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

// Conditional syntax in JSX
// https://stackoverflow.com/questions/53508119/what-is-the-syntax-for-a-simple-if-conditional-in-gatsby-js


const OptoutPage = () => {


  return (
    <Layout>
      <SEO title="Avmelding | Gratis.no" description={""}/>

      <div>

        <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow">
          <div className="container">
            <h2 style={{marginBottom:'30px'}} className="text-center text-sm-left">Avmelding <span className="h2-style">varsler</span></h2>

            <p className="infoPageParagraph">
              For tiden tilbyr ikke gratis.no varslinger (vi sender heller ikke ut varslinger).
            </p>
            <p className="infoPageParagraph">
              Når mulighet for dette kommer på plass vil det være mulig å melde seg av
              varslene på denne siden.
            </p>


            <div className="mt-xl-40 mt-50 text-center text-sm-right"><Link
              className="button button-icon button-icon-right button-black" to="/"><span
              className="icon novi-icon fa fa-chevron-right"/>Til forsiden</Link></div>
          </div>
        </section>

      </div>
    </Layout>
  )
}

export default OptoutPage


